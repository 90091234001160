<template>
    <span>
        <v-app v-if="$route.name == 'crear'">
            <!-- Sizes your content based upon application components -->
            <v-main>
                <!-- Provides the application the proper gutter -->
                <v-container fluid>
                    <!-- If using vue-router -->
                    <router-view></router-view>
                </v-container>
            </v-main>
            <v-footer app>
                <!-- -->
            </v-footer>
        </v-app>

        <span v-else>
            <router-view></router-view>
        </span>
    </span>
</template>

<script>
export default {};
</script>

<style></style>
